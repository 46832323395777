<template>
  <div class="composant-impression">
    <LabelEditable
      :locked="!user.adminAccessGranted || screenIsPhone"
      class="date-impression label-editable"
      format="date"
      v-model="impression.date_impression"
      @change="enregistrerModificationImpression"
    />
    <LabelEditable
      :locked="!user.adminAccessGranted || screenIsPhone"
      class="quantite-impression label-editable"
      v-model="impression.quantite_impression"
      @change="enregistrerModificationImpression"
    />
    <LabelEditable
      :locked="!user.adminAccessGranted || screenIsPhone"
      class="cout-impression label-editable"
      v-model="impression.cout_impression"
      @change="enregistrerModificationImpression"
    />
    <img
      v-if="user.adminAccessGranted && !screenIsPhone"
      alt=""
      src="assets/icones/icone-supprimer-carree.png"
      class="icone-supprimer element-clickable"
      @click="supprimerImpression"
    />
    <div v-else class="icone-supprimer"></div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ComposantImpression",
  props: {
    impression: {
      default: "",
    },
  },
  computed: {
    ...mapState(["user", "screenIsPhone"]),
  },
  methods: {
    ...mapActions(["phpPost", "modaleOuiNon"]),
    enregistrerModificationImpression() {
      this.$emit("update");
    },
    supprimerImpression() {
      this.$emit("delete");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
@import "src/assets/css/listeImpressions.scss";

.composant-impression {
  background-color: $fond-page;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $mobile-break-point) {
    width: 100%;
    padding: 5px;
  }
}
.label-editable {
  // width: 100px;
  justify-content: center;
  margin-right: 10px;
  height: 30px;
  @media (max-width: $mobile-break-point) {
    margin-right: 3px;
  }
  &.date-impression {
    width: $largeur-date;
    @media (max-width: $mobile-break-point) {
      margin-right: 3px;
      width: 40%;
    }
  }
  &.quantite-impression {
    width: $largeur-quantite;
    @media (max-width: $mobile-break-point) {
      width: 30%;
    }
  }
  &.cout-impression {
    width: $largeur-quantite;
    @media (max-width: $mobile-break-point) {
      width: 30%;
    }
  }
}
.icone-supprimer {
  height: 35px;
}
</style>
